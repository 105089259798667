import { statisticActions } from "store/slices/Statistic.slice";
import { useDispatch, useSelector } from "react-redux";

export const UseSelect = () => {
  const {
    questionStatuses,
    categoryQuestions,
    educations,
    professions,
    academicDegrees,
  } = useSelector((state) => state.stats);

  const dispatch = useDispatch();

  const selectPage = (item) => {
    try {
      const currentQuestionStatus = questionStatuses.find(
        (question) => question.regionId === item.regionId
      );
      const currentCagetoryQuestion = categoryQuestions.find(
        (category) => category.regionId === item.regionId
      );
      const currentEducation = educations.find(
        (education) => education.regionId === item.regionId
      );
      const currentProfession = professions.find(
        (profession) => profession.regionId === item.regionId
      );
      const currentAcademicDegree = academicDegrees.find(
        (academic) => academic.regionId === item.regionId
      );

      if (item) dispatch(statisticActions.setSelectedRegion(item));

      if (!currentProfession) return;
      if (!currentAcademicDegree) return;
      if (!currentEducation) return;
      if (!currentQuestionStatus) return;
      if (!currentCagetoryQuestion) return;

      dispatch(statisticActions.setSelectedEducation(currentEducation));
      dispatch(statisticActions.setSelectedProfession(currentProfession));
      dispatch(
        statisticActions.setSelectedAcademicDegree(currentAcademicDegree)
      );
      dispatch(
        statisticActions.setSelectedQuestionStatus(currentQuestionStatus)
      );
      dispatch(
        statisticActions.setSelectedQuestionCategory(currentCagetoryQuestion)
      );
    } catch (e) {}
  };

  return { selectPage };
};
