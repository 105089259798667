import { api } from "api/api";
import { endpoints } from "api/endpoints";

export const GetEducationStat = (data = "") =>
  api.get(endpoints.Stats.EducationStat(data));
export const GetProfessionStat = (data = "") =>
  api.get(endpoints.Stats.ProfessionStat(data));
export const GetAcademicDegree = (data = "") =>
  api.get(endpoints.Stats.AcademicDegreeStat(data));

export const GetCategoryQuestionCategoryStatistic = (data) =>
  api.get(endpoints.Question.CategoryQuestionCategoryStatistic(data || ""));
