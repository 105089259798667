import { api } from "api/api";
import { endpoints } from "api/endpoints";

const { Citizen } = endpoints;

export const getCitizens = async (data) =>
  api.post(Citizen.GetCitizens, JSON.stringify(data));

export const getCitizenById = async (data) =>
  api.get(Citizen.GetCitizenById(data));

export const CitizenApi = { getCitizenById, getCitizens };

export const GetGenderStatistic = () =>
  api.get(endpoints.Citizen.CitizenStatistic);
