import React, { useEffect, useState } from "react";
//
import { Delegate, Header, Orders, Curultai, Footer, Videos } from "features";
//
import styles from "./ExamplePage.module.scss";
import { ScrollTop, Splash } from "components";
import { UseData } from "hooks/UseData";

const pagesItems = [Header, Orders, Delegate, Videos, Curultai, Footer];

export const ExamplePage = () => {
  const [pages, setPages] = useState([pagesItems[0]]);
  //
  const { loading, getInitialData } = UseData();

  const loadMoreSection = () => {
    if (document.documentElement.scrollTop === 0) {
      setPages([pagesItems[0]]);
    }
    if (
      window.innerHeight + document.documentElement.scrollTop ===
      document.scrollingElement.scrollHeight
    ) {
      setPages((prev) => {
        const lastIndex = prev.length - 1;
        if (pagesItems[lastIndex + 1]) {
          return [...prev, pagesItems[lastIndex + 1]];
        }
        return prev;
      });
    }
  };

  useEffect(() => {
    // getInitialData();
  }, []);

  useEffect(() => {
    window.addEventListener("touchmove", loadMoreSection);

    return () => window.removeEventListener("touchmove", loadMoreSection);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", loadMoreSection);

    return () => window.removeEventListener("scroll", () => loadMoreSection);
  }, []);

  useEffect(() => {
    window.addEventListener("wheel", loadMoreSection);

    return () => window.removeEventListener("wheel", loadMoreSection);
  }, []);

  return (
    <>
      {loading ? (
        <Splash />
      ) : (
        pages.map((Item, key) => (
          <section key={key} className={styles.section}>
            <Item />
          </section>
        ))
      )}
      <ScrollTop />
    </>
  );
};
