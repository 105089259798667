import { api } from "api/api";
import { endpoints } from "api/endpoints";

export const GetQuestion = (data) =>
  api.get(endpoints.Question.GetQuestion(data || ""));

export const GetStatusStatistic = (data) =>
  api.get(endpoints.Question.StatusStatistic(data || ""));

export const GetCategoryStatistic = (data) =>
  api.get(endpoints.Question.CategoryStatistic(data || ""));

export const GetCategoryQuestionStatitstic = (data) =>
  api.get(endpoints.Question.CategoryQuestionStatistic(data || ""));
