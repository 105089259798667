export const dates = [
  {
    id: 2023,
    value: 2023,
    active: true,
  },
  {
    id: 2024,
    value: 2024,
    active: false,
  },
  {
    id: 2025,
    value: 2025,
    active: false,
  },
];
