export const endpoints = {
  AccountApi: {
    Login: "/AccountApi/Login",
  },
  Citizen: {
    GetCitizenById: (query) => "/Citizen/GetCitizenById" + query,
    GetCitizens: "/Citizen/GetCitizens",
    CitizenStatistic: "/Citizen/CitizenStatistic",
  },
  Location: {
    GetLocationsByDeep: (query) => "/Location/GetLocationsByDeep?" + query,
    FindByName: "/Location/FindByName",
    GetChildsById: (query) => "/Location/GetChildsById?" + query,
  },
  Order: {
    Get: "/Order/Get",
  },
  Question: {
    GetQuestion: (query) => "/Question/GetQuestion" + query,
    StatusStatistic: (query) => "/Question/StatusStatistic" + query,
    CategoryStatistic: (query) => "/Question/CategoryStatistic" + query,
    CategoryQuestionCategoryStatistic: (query) =>
      "Question/GetQuestionCategoryStatistics" + query,
  },
  Stats: {
    EducationStat: (query) => "/Citizen/EducationStatistic" + query,
    ProfessionStat: (query) => "/Citizen/ProfessionStatistic" + query,
    AcademicDegreeStat: (query) => "/Citizen/AcademicDegreeStatistic" + query,
  },
  Login: "/accountApi/login",
  Slides: {
    Next: "/queue/next",
    Prev: "/queue/back",
  },
};
