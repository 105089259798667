import {
  CitizenApi,
  GetGenderStatistic,
  getCitizenById,
  getCitizens,
} from "api/routes/Citizens";
import { GetLocationByDeep, GetLocationChildById } from "api/routes/Location";
import { GetCategoryStatistic, GetStatusStatistic } from "api/routes/Question";
import {
  GetAcademicDegree,
  GetCategoryQuestionCategoryStatistic,
  GetEducationStat,
  GetProfessionStat,
} from "api/routes/Statistic";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { delegationActions } from "store/slices/Delegation.slice";
import { statisticActions } from "store/slices/Statistic.slice";

export const UseData = () => {
  const [loading, setLoading] = useState(false);
  //
  const filters = useSelector((state) => state.delegation.filters);
  const { selectedRegion } = useSelector((state) => state.stats);
  const { citizensResult } = useSelector((state) => state.delegation);
  //
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const getDelegationInitalData = async () => {
    try {
      const regionData = (await GetLocationByDeep("deep=1")).data;

      dispatch(
        delegationActions.setDelegationFilters({
          ...filters,
          region: regionData,
        })
      );
    } catch (e) {}
  };

  const getCitizenByidData = async (id) => {
    try {
      const { data } = await getCitizenById("?id=" + id);

      return data;
    } catch (e) {}
  };

  const getDistrictData = async (id) => {
    try {
      const districtData = (await GetLocationChildById("id=" + id)).data;

      dispatch(
        delegationActions.setDelegationFilters({
          ...filters,
          ak: {},
          district: districtData,
        })
      );
    } catch (e) {}
  };

  const getMoreItems = async (params, isSearch) => {
    try {
      setLoading(true);
      const { data } = await getCitizens(params);

      if (!data.citizens.length) return;

      if (isSearch) {
        return dispatch(
          delegationActions.setCitizensResult(data.citizens || [])
        );
      }

      dispatch(
        delegationActions.setCitizensResult(
          citizensResult.concat(data.citizens)
        )
      );
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const getDelegations = async (params) => {
    try {
      setLoading(true);
      const { data } = await getCitizens({
        pageNumber: 1,
        pageSize: 50,
        ...params,
      });

      dispatch(delegationActions.setCitizensResult(data?.citizens || []));
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const getInitialData = async () => {
    try {
      setLoading(true);
      await getInitilaQuestionCategoryStatistic();
      await getStatisticData();

      // const { data } = await GetGenderStatistic();

      const citizenData = await CitizenApi.getCitizens({
        pageNumber: 1,
        pageSize: 50,
        locationId: null,
      });
      //

      if (!citizenData.data.citizens.length) return;

      // dispatch(statisticActions.setRegions(data));
      dispatch(
        delegationActions.setDelegationData(
          citizenData.data.citizens?.sort(() => Math.random() - 0.5) || []
        )
      );
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const getInitilaQuestionCategoryStatistic = async () => {
    try {
      const { data } = await GetGenderStatistic("?year=2023");
      const categoryQuestionCategoryStatistic =
        await GetCategoryQuestionCategoryStatistic("?year=2023");

      const regions = [];

      for (let region of data) {
        if (region.regionId === 4948) {
          regions.push({ ...region, regionChilds: [] });
          continue;
        }

        const regionChilds = await GetLocationChildById(
          "id=" + region.regionId
        );

        regions.push({ ...region, regionChilds: regionChilds.data });
      }

      dispatch(
        statisticActions.setCategoryQuestionStatistic(
          categoryQuestionCategoryStatistic.data
        )
      );

      dispatch(statisticActions.setRegions(regions));
    } catch (e) {
      console.log(e);
    }
  };

  const getStatisticData = async () => {
    try {
      setLoading(true);
      // const { data } = await GetGenderStatistic("?year=2023");
      const questionsData = await GetStatusStatistic("?year=2023");
      const categoryData = await GetCategoryStatistic("?year=2023");
      const educationData = await GetEducationStat("?year=2023");
      const professionsData = await GetProfessionStat("?year=2023");
      const academicDegreesData = await GetAcademicDegree("?year=2023");

      // dispatch(statisticActions.setRegions(data));
      dispatch(statisticActions.setQuestionStatuses(questionsData.data));
      dispatch(
        statisticActions.setSelectedQuestionStatus(questionsData.data[0])
      );
      dispatch(statisticActions.setQuestionsCategory(categoryData.data));
      dispatch(
        statisticActions.setSelectedQuestionCategory(categoryData.data[0])
      );
      dispatch(statisticActions.setEducations(educationData.data));
      dispatch(statisticActions.setProfessions(professionsData.data));
      dispatch(statisticActions.setAcademicDegree(academicDegreesData.data));
      dispatch(
        statisticActions.setSelectedAcademicDegree(academicDegreesData.data[0])
      );
      dispatch(statisticActions.setSelectedEducation(educationData.data[0]));
      dispatch(statisticActions.setSelectedProfession(professionsData.data[0]));
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    getInitialData,
    getDelegationInitalData,
    getDistrictData,
    getDelegations,
    getCitizenByidData,
    getMoreItems,
  };
};
