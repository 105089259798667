import React from "react";
//
import { motion } from "framer-motion";
//
import s from "./BurgerMenu.module.scss";
//
import MenuIcon from "assets/images/menu.svg";
import MenuIconBack from "assets/images/close.svg";

export const BurgerMenu = ({
  menuListItems,
  navButtons,
  isOpen,
  langListItems,
  setIsOpen,
}) => {
  const openMenu = () => {
    setIsOpen(true);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  if (!isOpen)
    return (
      <div className={s.openMenuIcon}>
        <button type="button" onClick={openMenu}>
          <img src={MenuIcon} alt="menu" />
        </button>
      </div>
    );

  return (
    <motion.div className={s.menu} initial={{ y: -100 }} animate={{ y: 0 }}>
      <div className={s.openMenuIcon}>
        <button type="button" onClick={closeMenu}>
          <img src={MenuIconBack} alt="menu" />
        </button>
      </div>
      <div className={s.menuList}>
        {navButtons &&
          navButtons.map((item, key) => (
            <div key={key}>
              <button key={key} onClick={item.onClick}>
                {item.title}
              </button>
            </div>
          ))}
      </div>
      <div className={s.langs}>
        {langListItems &&
          langListItems.map((item, key) => (
            <button
              type="button"
              key={key}
              onClick={() => item.onClick(item.id)}
            >
              {item.fullName}
            </button>
          ))}
      </div>
    </motion.div>
  );
};
