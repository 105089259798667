import React from "react";
import cn from "classnames";
//
import { motion } from "framer-motion";
//
import styles from "./Title.module.scss";

export const Title = ({ text, className }) => {
  return (
    <motion.p
      initial={{ x: -1000 }}
      animate={{ x: 0 }}
      transition={{ type: "spring", duration: 1 }}
      className={cn(styles.text, className)}
    >
      {text}
    </motion.p>
  );
};
