import React from "react";
//
import styles from "./Splash.module.scss";
//
import LogoImage from "assets/images/header/header-icon.png";

export const Splash = () => {
  return (
    <div className={styles.splash}>
      <div>
        <img src={LogoImage} alt="logo" />
        <div className={styles.wave}></div>
      </div>
    </div>
  );
};
