import React from "react";
//
import styles from "./DelegatePage.module.scss";
import { DelegatesHeader } from "features";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { getCitizenById } from "api/routes/Citizens";
import { useState } from "react";
import { motion } from "framer-motion";

import { Loading } from "components";
//

import { GetQuestion } from "api/routes/Question";
import { useTranslation } from "react-i18next";
import { Question } from "./Copmonents/Question/Question";

// import UzonImg from "assets/images/uzor.svg";

export const DelegatePage = () => {
  const [delegat, setDelegat] = useState(null);
  const [question, setQuestion] = useState([]);
  const { id } = useParams();
  const { i18n, t } = useTranslation();
  const [isLoading, setLoading] = useState(false);

  const getData = async () => {
    try {
      setLoading(true);
      const { data } = await getCitizenById("?id=" + id);
      const questionData = await GetQuestion("?citizenId=" + id);

      setDelegat(data);
      setQuestion(questionData.data);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const getFromDate = (date) => {
    const newDate = new Date(date).toLocaleDateString();
    return newDate;
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <DelegatesHeader
        nonFilter
        text={t("delegats.oneTitle")}
        textStyle={styles.t}
      />
      {isLoading ? (
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 40 }}
        >
          <Loading />
        </div>
      ) : (
        <div>
          <motion.div
            className={styles.delegat}
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{
              type: "spring",
              duration: 1,
            }}
          >
            {delegat && (
              <>
                <div className={styles.header}>
                  <div className={styles.avatar}>
                    <img src={delegat.photoPath} alt="avatar" />
                    <p name="name">
                      {delegat.lastName} {delegat.firstName}{" "}
                      {delegat.paternalName}
                    </p>
                    <p name="whereFrom">{delegat.location.name}</p>
                  </div>
                  {/* <hr className={styles.hr}></hr> */}
                  <div className={styles.about}>
                    <div>
                      <div className={styles.info}>
                        <span>{t("delegat.info.year")}</span>
                        <p className={styles.bold}>
                          {getFromDate(delegat.birthDate)}
                        </p>
                      </div>
                      <div className={styles.info}>
                        <span>{t("delegat.info.whereFrom")}</span>
                        <p className={styles.bold}>
                          {i18n.language === "kg"
                            ? delegat.сitizenship.nameKy
                            : delegat.сitizenship.nameRu}
                        </p>
                      </div>
                      <div className={styles.info}>
                        <span>{t("delegat.info.nation")}</span>
                        <p className={styles.bold}>
                          {i18n.language === "kg"
                            ? delegat.nation.nameKy
                            : delegat.nation.nameRu}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div>
                        <span>{t("delegat.info.education")}</span>
                        <p className={styles.bold}>
                          {i18n.language === "kg"
                            ? delegat.education.nameKy
                            : delegat.education.nameRu}

                          <div>
                            {i18n.language === "kg"
                              ? delegat.profession.nameKy
                              : delegat.profession.nameRu}
                          </div>
                          {delegat.academicDegree &&
                          delegat.academicDegree.id !== 1 ? (
                            <div>
                              {i18n.language === "kg"
                                ? delegat.academicDegree.nameKy
                                : delegat.academicDegree.nameRu}
                            </div>
                          ) : null}
                        </p>
                      </div>
                    </div>
                    <div>
                      {delegat.workPlace && (
                        <div>
                          <span>{t("delegat.info.workPlace")}</span>
                          <p className={styles.bold}>{delegat.workPlace}</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </motion.div>

          {question.length &&
            question.map((item) => (
              <motion.div
                key={item.id}
                className={styles.questionBlock}
                initial={{ y: 500 }}
                animate={{ y: 0 }}
                transition={{
                  type: "spring",
                  duration: 1,
                }}
              >
                <Question question={item} className={styles.question} />
                <hr
                  style={{
                    margin: "20px 0",
                    background: "#f9a51a",
                    height: 1,
                    border: "none",
                    borderRadius: 10,
                  }}
                ></hr>
              </motion.div>
            ))}
        </div>
      )}
    </div>
  );
};
