import React, { useEffect } from "react";

import styles from "./MotinoringPage.module.scss";
import { DelegatesHeader } from "features";
import { CategoryQuestionStatusGroup } from "features/Statistic/Stat/Rechart/BarChart";
import { useDispatch, useSelector } from "react-redux";
import { HorizontalMenu } from "./Menu";
import { useTranslation } from "react-i18next";
import { appActions } from "store/slices/App.slice";

const colors3 = ["#F69B1E", "#A456E2", "#17E198"];

export const MonitoringPage = () => {
  const dispatch = useDispatch();
  const { categoryQuestionsStatistic } = useSelector((state) => state.stats);
  const { t, i18n } = useTranslation();
  const texts = [
    t("monitoring.byCategories"),
    t("monitoring.byRegions"),
    t("monitoring.byAges"),
    t("monitoring.byGender"),
  ];

  const canculate = (element, colorKey) => {
    const data = {
      labels: [],
      datasets: [],
    };

    let labels = [];

    for (let key in element) {
      const categoryNames =
        i18n.language === "kg"
          ? element[key].category.nameKy
          : element[key].category.nameRu;

      data.labels.push(categoryNames);

      for (let key2 in element[key].statuses) {
        const label = labels.find(
          (l) => l.id === element[key].statuses[key2].id
        );
        if (label) continue;

        labels.push({
          id: element[key].statuses[key2].id,
          name:
            i18n.language === "kg"
              ? element[key].statuses[key2].nameKy
              : element[key].statuses[key2].nameRu,
        });
      }
    }

    labels.forEach((el, key) => {
      data.datasets.push({
        label: el.name,
        data: element.map(
          (itm) => itm.statuses.find((item) => item.id === el.id)?.count || []
        ),
        backgroundColor: colors3[el.id - 1],
      });
    });
    return data;
  };

  useEffect(() => {
    dispatch(appActions.setInfoMessage(t("monitoring.willbe")));
  }, []);

  return (
    <div className={styles.content}>
      <DelegatesHeader nonFilter text={t("home.header.monitor")} />
      <HorizontalMenu />
      <div className={styles.cards}>
        {categoryQuestionsStatistic &&
          Object.keys(categoryQuestionsStatistic).map((item, key) => (
            <div key={key} className={styles.card}>
              <CategoryQuestionStatusGroup
                data={canculate(categoryQuestionsStatistic[item], key)}
                text={texts[key]}
                horizontal={!key}
                name={item}
              />
            </div>
          ))}
      </div>
    </div>
  );
};
