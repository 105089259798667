import React, { useState } from "react";
import { motion } from "framer-motion";
import classNames from "classnames";
//
import styles from "./Question.module.scss";
//
import ShowIcon from "assets/images/delegate/Show.png";
import WorkIcon from "assets/images/delegate/work.png";
import DeclineIcon from "assets/images/delegate/decline.png";
import CompleteIcon from "assets/images/delegate/complete.png";

import { useTranslation } from "react-i18next";

const allowedImages = ["jpg", "webp", "png"];

const splitName = (value, split) => {
  const splited = value.split(split);

  return splited[splited.length - 1];
};

export const Question = ({ question, className }) => {
  const [showFull, setShowFull] = useState(true);
  const { t, i18n } = useTranslation();

  const formatDate = (date) => {
    const currenDate = date.split("T");

    return currenDate[0] + " | " + currenDate[1].split(".")[0];
  };

  const getStatus = () => {
    if (question.questionStatus.id === 1) {
      return { icon: ShowIcon, color: "#F5991F" };
    }
    if (question.questionStatus.id === 2) {
      return { icon: WorkIcon, color: "#1FACCB" };
    }
    if (question.questionStatus.id === 4) {
      return { icon: DeclineIcon, color: "#CE373A" };
    }
    if (question.questionStatus.id === 3) {
      return { icon: CompleteIcon, color: "#27CC37" };
    }
    return { icon: ShowIcon, color: "#F5991F" };
  };

  return (
    <div style={{ width: "100%" }}>
      <div className={styles.blockItem}>
        <div className={styles.date}>{formatDate(question?.createdDate)}</div>
        <motion.div className={classNames(styles.block, className)}>
          <p className={styles.title}>
            {t("delegat.question.text")} <span>{question.subject}</span>
          </p>
          <span
            className={classNames(styles.status)}
            style={{
              color: getStatus().color,
            }}
          >
            <img src={getStatus().icon} alt="icon" />
            {i18n.language === "kg"
              ? question.questionStatus.nameKy
              : question.questionStatus.nameRu}
          </span>
        </motion.div>
        <div dangerouslySetInnerHTML={{ __html: question?.description }}></div>
      </div>
      <motion.div
        initial={{ y: 100 }}
        animate={{
          y: 0,
        }}
      >
        {question.answers.length ? (
          <div>
            {[...question.answers].reverse().map((item, key) => (
              <div className={styles.blockItem2} key={key}>
                <div className={styles.date}>
                  {formatDate(item?.answerDate)}
                </div>
                <p className={styles.answer}>{t("delegat.question.answer")}</p>
                <div key={item.id} className={styles.block}>
                  <div dangerouslySetInnerHTML={{ __html: item.description }} />
                </div>
                {item.documents.length ? (
                  <div className={styles.doc}>
                    <p>{t("delegat.pinned")}</p>
                    {item.documents.map((item) =>
                      allowedImages.includes(splitName(item.name, ".")) ? (
                        <div className={styles.viewImg} key={item.id}>
                          <a href={item.documentPath} target="_blank">
                            <img src={item.documentPath} />
                          </a>
                        </div>
                      ) : (
                        <div key={item.id}>
                          <a
                            href={item.documentPath}
                            id={item.id}
                            target="_blank"
                          >
                            {item.name}
                          </a>
                        </div>
                      )
                    )}
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        ) : (
          <p className={styles.noAnswer}>{t("delegat.question.noAnswer")}</p>
        )}
      </motion.div>
    </div>
  );
};
