import { BrowserRouter } from "react-router-dom";
import { UseRoutes } from "hooks/UseRoutes";
//
import "./App.css";
import { Notify } from "components";
import { useEffect } from "react";
import { Snows } from "components/Snow/Snows";

function App() {
  const routes = UseRoutes();

  useEffect(() => {
    document.title = "Курултай 2023";
  }, []);

  return (
    <div className="App">
      <BrowserRouter>{routes}</BrowserRouter>
      <Notify />
    </div>
  );
}

export default App;
