import React, { useEffect } from "react";

import styles from "./Statistic.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  RadialLinearScale,
} from "chart.js";

import { Stat } from "./Stat/RegionCard";
import { statisticActions } from "store/slices/Statistic.slice";
import { useTranslation } from "react-i18next";

ChartJS.register(
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement
);

export const Statistic = () => {
  const { selectedRegion, regions, questionStatuses } = useSelector(
    (state) => state.stats
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(statisticActions.setSelectedRegion(regions[0]));
  }, [regions]);

  if (!regions.length || !questionStatuses.length) return;

  return <div className={styles.stats}>{selectedRegion ? <Stat /> : null}</div>;
};
