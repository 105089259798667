import React from "react";
//
import styles from "./Curultai.module.scss";
import { Title } from "components";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

//
// import FlagImage from "assets/images/curultai/flag.png";

export const Curultai = () => {
  const { t } = useTranslation();
  return (
    <div id="curultai">
      <motion.div
        className={styles.curultai}
        initial={{ x: 1000 }}
        animate={{ x: 0 }}
        transition={{
          type: "spring",
          duration: 1,
        }}
      >
        <div>
          <p className={styles.date}>{t("home.curultai.date")}</p>
          <p className={styles.when}>{t("home.curultai.text")}</p>
        </div>
        <p className={styles.desc}>{t("home.curultai.desc")}</p>
        {/* <img src={FlagImage} alt="flag" /> */}
      </motion.div>
    </div>
  );
};
