import React, { useMemo } from "react";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { colors } from "../MainCard/Menu/Menu";
import { motion } from "framer-motion";
import styles from "./Rechart.module.scss";
import { colors2 } from "./PieChart";

export const AgesStat = () => {
  const { selectedRegion } = useSelector((state) => state.stats);
  const { t, i18n } = useTranslation();

  const data = useMemo(() => {
    const keys = Object.keys(selectedRegion)
      .map((itm, key) => {
        if (itm.includes("from")) {
          const old = itm.split("from")[1].split("To");

          return {
            value: selectedRegion[itm],
            label: `${old[0]} ${old[1] ? " - " + old[1] : "+"}`,
          };
        }
      })
      .filter((item) => item);

    return {
      labels: keys.map((label) => label.label),
      datasets: [
        {
          label: t("count"),
          data: keys.map((value) => value.value),
          backgroundColor: colors,
        },
      ],
    };
  }, [selectedRegion, i18n.language]);

  return (
    <div className={styles.bar}>
      <Bar
        data={data}
        options={{
          scales: {
            y: {
              ticks: {
                stepSize: 10,
              },
            },
          },
          plugins: {
            legend: {
              display: false,
              position: "bottom",
            },
          },
        }}
      />
    </div>
  );
};

export const EducationStat = () => {
  const { selectedRegion, selectedAcademicDegrees } = useSelector(
    (state) => state.stats
  );
  const { t, i18n } = useTranslation();

  const data = useMemo(() => {
    return {
      labels: selectedAcademicDegrees?.academicDegrees.map((item) =>
        i18n.language === "kg"
          ? item.academicDegree.nameKy
          : item.academicDegree.nameRu
      ),
      datasets: [
        {
          label: t("count"),
          data: selectedAcademicDegrees?.academicDegrees
            .map((item) => item.totalCitizens)
            .sort((a, b) => b - a),
          backgroundColor: colors2,
        },
      ],
    };
  }, [selectedRegion, i18n.language]);
  return (
    <div className={styles.bar1}>
      <Bar
        data={data}
        options={{
          scales: {
            y: {
              ticks: {
                stepSize: 10,
              },
            },
          },
          plugins: {
            legend: {
              display: false,
              position: "bottom",
              labels: {
                font: {
                  family: "Roboto",
                  size: 16,
                },
              },
            },
          },
        }}
      />
    </div>
  );
};

export const ProfessionStat = () => {
  const { selectedRegion, selectedProfession } = useSelector(
    (state) => state.stats
  );
  const { t, i18n } = useTranslation();

  const data = useMemo(() => {
    return {
      labels: selectedProfession?.professions.map((item) =>
        i18n.language === "kg" ? item.profession.nameKy : item.profession.nameRu
      ),
      datasets: [
        {
          label: t("count"),
          data: selectedProfession?.professions
            .map((item) => item.totalCitizens)
            .sort((a, b) => b - a),
          backgroundColor: colors2,
        },
      ],
    };
  }, [selectedRegion, i18n.language]);

  return (
    <div className={styles.bar1}>
      <Bar
        data={data}
        options={{
          scales: {
            y: {
              ticks: {
                stepSize: 10,
              },
            },
          },
          plugins: {
            legend: {
              display: false,
              position: "bottom",
              labels: {
                font: {
                  family: "Roboto",
                  size: 16,
                },
              },
            },
          },
        }}
      />
    </div>
  );
};

export const CategoryQuestionStatusGroup = ({ text, horizontal, data }) => {
  return (
    <div className={styles.category}>
      <motion.p
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        className={styles.text}
      >
        {text}
      </motion.p>
      <Bar
        data={data}
        options={{
          indexAxis: horizontal ? "y" : "x",
          responsive: true,
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
            },
          },
        }}
      />
    </div>
  );
};
