import { api } from "api/api";
import { endpoints } from "api/endpoints";

export const PostLoginAdmin = (data) =>
  api.post(endpoints.Login, JSON.stringify(data));

export const PatchNextSlide = ({ token }) =>
  api.patch(endpoints.Slides.Next, null, {
    headers: { Authorization: "Bearer " + token },
  });
export const PatchPrevSlide = ({ token }) =>
  api.patch(endpoints.Slides.Prev, null, {
    headers: { Authorization: "Bearer " + token },
  });
