import Slide1 from "assets/images/mock/slide1.png";
import Slide2 from "assets/images/mock/slide2.png";
import Slide3 from "assets/images/mock/slide3.png";

import Slide5 from "assets/images/mock/slide5.jpg";
import Slide7 from "assets/images/mock/slide7.jpg";
import Slide8 from "assets/images/mock/slide8.jpg";
import Slide9 from "assets/images/mock/slide9.jpg";
import Slide10 from "assets/images/mock/slide10.jpg";
import Slide11 from "assets/images/mock/slide11.jpg";
import Slide12 from "assets/images/mock/slide12.jpg";
import Slide13 from "assets/images/mock/slide13.jpg";
import Slide14 from "assets/images/mock/slide14.jpg";
import Slide15 from "assets/images/mock/slide15.jpg";
import Slide16 from "assets/images/mock/slide16.jpg";
import Slide17 from "assets/images/mock/slide17.jpg";
import Slide18 from "assets/images/mock/slide18.png";

export const slider_mocks = [
  Slide18,
  Slide3,
  Slide1,
  Slide5,
  Slide7,
  Slide8,
  Slide9,
  Slide10,
  Slide11,
  Slide12,
  Slide13,
  Slide14,
  Slide16,
  Slide17,
];
