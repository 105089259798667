import { useEffect, useMemo, useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { createSignalRContext } from "react-signalr/signalr";

import styles from "./Queue.module.scss";
import ImgMontain from "assets/images/header/montain.png";
import { baseUrl } from "api/api";
import { QueueAdaptive } from "./Adative";
import classNames from "classnames";
import io from "socket.io-client";

const SignalRContext = createSignalRContext();

const WEBSOCKET_URL = baseUrl.dev.replace("/api/", "") + "/queueHub";

const SHORT_TIMES = 30;

export const QueuePage = ({ WithNavigates }) => {
  const { current: socket } = useRef(io("/", { autoConnect: false }));
  const [speakersList, setSpeakersList] = useState([]);
  const [speakerNow, setSpeakerNow] = useState(null);
  const [fiveNext, setFiveNext] = useState([]);
  const [total, setTotal] = useState([]);
  const [isAdaptive, setIsAdaptive] = useState(false);
  const [timerCount, setTimerCount] = useState(0);

  SignalRContext.useSignalREffect("ReceiveUpdatedQueue", (queue) => {
    const data = JSON.parse(queue);

    if (!data) return;

    const willSpeakers = data.filter((speaker) => !speaker.State);

    setTotal(willSpeakers);
    setSpeakerNow(willSpeakers.shift());

    const five = [];
    const others = [];

    willSpeakers.forEach((speaker, i) =>
      i < 5 ? five.push(speaker) : others.push(speaker)
    );

    setFiveNext(five);

    setSpeakersList(others);
  });

  const resize = (e) => {
    if (e.target.innerWidth < 900) {
      setIsAdaptive(true);
    } else {
      setIsAdaptive(false);
    }
  };

  const toHoursAndMinutes = (totalSeconds) => {
    const totalMinutes = Math.floor(totalSeconds / 60);

    let seconds = totalSeconds % 60;
    let hours = Math.floor(totalMinutes / 60);
    let minutes = totalMinutes % 60;

    const time = [hours, minutes, seconds].map((item) =>
      item < 10 ? `0${item}` : `${item}`
    );

    return `${time[1]}:${time[2]}`;
  };

  const startTimer = () => {
    if (WithNavigates) {
      socket.emit("startTimer", {});
    }
  };

  const addTime = () => {
    if (WithNavigates) {
      if (timerCount === 0) {
        socket.emit("addTime", {});
      }
    }
  };

  useEffect(() => {
    socket.connect();
    socket.on("nextTick", (count) => {
      setTimerCount(count);
    });
    socket.on("timerCount", (count) => setTimerCount(count));

    return () => {
      if (socket) socket.close();
    };
  }, [socket]);

  useEffect(() => {
    if (WithNavigates) {
      socket.emit("clearTimer", {});
    }
  }, [speakerNow]);

  useEffect(() => {
    window.addEventListener("resize", resize);
    resize({ target: { innerWidth: window.innerWidth } });
    return () => {
      window.addEventListener("resize", resize);
    };
  }, []);

  return (
    <SignalRContext.Provider
      connectEnabled={true}
      accessTokenFactory={() => ""}
      dependencies={[""]}
      url={WEBSOCKET_URL}
    >
      <div>
        {speakerNow ? (
          <div className={styles.speakerNow} id={speakerNow?.Id}>
            <p className={styles.title}>Чыгып сүйлөгөн делегат</p>
            <div className={styles.flex}>
              {!isAdaptive && WithNavigates ? WithNavigates.prev : <div />}
              <AnimatePresence mode="popLayout">
                <motion.div
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{ type: "spring", duration: 2.5 }}
                  className={styles.delegat}
                  key={speakerNow?.Citizen.Pin}
                  exit={{ y: -500 }}
                >
                  <img
                    src={ImgMontain}
                    alt="montain"
                    className={styles.montain}
                  />
                  <div>
                    <img src={speakerNow?.Citizen.PhotoPath} />
                    <div>
                      <p className={styles.name}>
                        {speakerNow?.Citizen.LastName}{" "}
                        {speakerNow?.Citizen.FirstName}{" "}
                        {speakerNow?.Citizen.PaternalName}
                      </p>
                      <p className={styles.location}>
                        {speakerNow?.Citizen.Location.Value}
                      </p>
                    </div>
                  </div>
                  <div className={styles.mainKey}>
                    {speakerNow?.OrderNumber}
                  </div>
                  <div
                    onDoubleClick={addTime}
                    onClick={startTimer}
                    className={classNames(
                      styles.timer,
                      timerCount < SHORT_TIMES && styles.shake
                    )}
                    style={{
                      color: timerCount < SHORT_TIMES ? "#CE373A" : "#aafacf",
                    }}
                  >
                    {toHoursAndMinutes(timerCount)}
                  </div>
                </motion.div>
              </AnimatePresence>
              {!isAdaptive && WithNavigates ? WithNavigates.next : <div />}
            </div>
          </div>
        ) : (
          <p style={{ fontSize: 55, textAlign: "center" }}>
            Бардык делегаттар сүйлөп бүтүштү
          </p>
        )}
        {isAdaptive ? (
          <QueueAdaptive total={total} />
        ) : (
          <div className={styles.others}>
            {fiveNext.length ? (
              <div className={styles.willFiveSpeakers}>
                <p className={styles.title}>Кийинкиси </p>
                <div>
                  <AnimatePresence mode="popLayout">
                    {fiveNext.map((speaker, key) => (
                      <motion.div
                        initial={{ y: 100 }}
                        animate={{ y: 0 }}
                        transition={{ type: "spring", duration: 2.5 }}
                        className={styles.smallDelegat}
                        key={speaker.Citizen.Pin}
                        id={speaker.Id}
                        exit={{
                          y: -300,
                          x: speakersList.length ? 200 : 0,
                          scale: 0,
                        }}
                      >
                        <div className={styles.key}>{speaker?.OrderNumber}</div>
                        <div>
                          <img src={speaker?.Citizen.PhotoPath} />

                          <div>
                            <p>
                              {speaker?.Citizen.LastName}{" "}
                              {speaker?.Citizen.FirstName}{" "}
                              {speaker?.Citizen.PaternalName}
                            </p>
                            <p>{speaker?.Citizen.Location.Value}</p>
                          </div>
                        </div>
                      </motion.div>
                    ))}
                  </AnimatePresence>
                </div>
              </div>
            ) : null}

            {speakersList.length ? (
              <div className={styles.willSpeakers}>
                <p className={styles.title}>Кезекте</p>
                <div className={styles.scroll}>
                  <AnimatePresence mode="popLayout">
                    {speakersList.map((speaker, key) => (
                      <motion.div
                        initial={{ x: 100 }}
                        animate={{ x: 0 }}
                        transition={{ type: "spring", duration: 2.5 }}
                        className={styles.smallDelegat}
                        key={speaker.Citizen.Pin}
                        id={speaker.Id}
                        exit={{ scale: 0, x: -500, y: 500 }}
                      >
                        <div className={styles.key}>{speaker?.OrderNumber}</div>
                        <div>
                          <img src={speaker?.Citizen.PhotoPath} />
                          <div>
                            <p>
                              {speaker?.Citizen.LastName}{" "}
                              {speaker?.Citizen.FirstName}{" "}
                              {speaker?.Citizen.PaternalName}
                            </p>
                            <p>{speaker?.Citizen.Location.Value}</p>
                          </div>
                        </div>
                      </motion.div>
                    ))}
                  </AnimatePresence>
                </div>
              </div>
            ) : null}
          </div>
        )}
      </div>
    </SignalRContext.Provider>
  );
};
