import React, { useEffect, useState } from "react";
import cn from "classnames";
import { motion } from "framer-motion";
//
import styles from "./Select.module.scss";
//
import LangIcon from "assets/images/header/lang.svg";
import DownDropIcon from "assets/images/header/down-arrow.svg";
//

import { useTranslation } from "react-i18next";

import UseVisible from "hooks/UseVisible";
//
import DropIcon from "assets/images/dropdown.svg";
import { useDispatch, useSelector } from "react-redux";
import { delegationActions } from "store/slices/Delegation.slice";
import { GetLocationChildById } from "api/routes/Location";
import { appActions } from "store/slices/App.slice";
import { UseData } from "hooks/UseData";
//
import CloseIcon from "assets/images/close.svg";

export const Select = ({ item }) => {
  const { getDelegations } = UseData();
  const { selected, filters } = useSelector((state) => state.delegation);
  const { t } = useTranslation();
  //
  const { ref, setIsComponentVisible, isComponentVisible } = UseVisible();

  const dispatch = useDispatch();

  const getDistrict = async (itm) => {
    try {
      const { data } = await GetLocationChildById("id=" + itm.id);

      dispatch(
        delegationActions.setDelegationSelected({
          ak: {},
          district: {},
          region: itm,
        })
      );

      dispatch(
        delegationActions.setDelegationFilters({
          ...filters,
          ak: {},
          district: data,
        })
      );
      getDelegations({ locationId: itm.id });
    } catch (e) {}
  };

  const getAk = async (itm) => {
    try {
      const { data } = await GetLocationChildById("id=" + itm.id);

      dispatch(
        delegationActions.setDelegationSelected({
          ...selected,
          district: itm,
          ak: {},
        })
      );

      dispatch(
        delegationActions.setDelegationFilters({ ...filters, ak: data })
      );
      getDelegations({ locationId: itm.id });
    } catch (e) {}
  };

  const onSelect = (itm) => {
    if (itm.id === 0) {
      dispatch(
        delegationActions.setDelegationSelected({
          region: {},
          district: {},
          ak: {},
        })
      );
      dispatch(
        delegationActions.setDelegationFilters({
          ...filters,
          district: {},
          ak: {},
        })
      );
      setIsComponentVisible(false);
      return getDelegations({ locationId: null });
    }

    if (itm.deep === 1) {
      getDistrict(itm);
    }
    if (itm.deep === 2) {
      getAk(itm);
    }
    if (itm.deep === 3) {
      dispatch(
        delegationActions.setDelegationSelected({ ...selected, ak: itm })
      );
      getDelegations({ locationId: itm.id });
    }
    setIsComponentVisible(false);
  };

  const resetFilter = (item) => {
    if (item.id === "region") {
      dispatch(
        delegationActions.setDelegationSelected({
          region: {},
          district: {},
          ak: {},
        })
      );
      dispatch(
        delegationActions.setDelegationFilters({ ...filters, district: [] })
      );
      getDelegations({ locationId: null });
    }

    if (item.id === "district") {
      dispatch(
        delegationActions.setDelegationSelected({
          ...selected,
          district: {},
          ak: {},
        })
      );
      dispatch(delegationActions.setDelegationFilters({ ...filters, ak: [] }));
      getDelegations({ locationId: selected.region.id });
    }

    if (item.id === "ak") {
      dispatch(
        delegationActions.setDelegationSelected({
          ...selected,
          ak: {},
        })
      );
      getDelegations({ locationId: selected.district.id });
    }
  };

  return (
    <div className={styles.select}>
      <div className={styles.selectBtn} key={item.title}>
        <button type="button" onClick={() => setIsComponentVisible(true)}>
          {selected[item.id]?.name || item.title}
        </button>

        {selected[item.id]?.name ? (
          <img
            src={CloseIcon}
            alt="restore"
            onClick={() => resetFilter(item)}
          />
        ) : (
          <img
            src={DropIcon}
            alt="drop"
            onClick={() => setIsComponentVisible(true)}
          />
        )}
      </div>
      {isComponentVisible && (
        <motion.nav
          className={styles.list}
          ref={ref}
          initial={{ scale: 0, translate: "-50%" }}
          animate={{ scale: 1, translate: "-50%" }}
        >
          {item.data.length ? (
            item.data.map((itm) => (
              <li
                type="button"
                className={styles.selBtn}
                onClick={() => onSelect(itm)}
                key={itm.id}
              >
                {itm?.name}
              </li>
            ))
          ) : (
            <div>
              {t("delegats.select.firstSelect")}

              {item.id === "district" ? "Область" : "Район"}
            </div>
          )}
        </motion.nav>
      )}
    </div>
  );
};

const languages = [
  {
    id: "kg",
    value: "кырг",
    fullName: "кыргызча",
  },
  {
    id: "ru",
    value: "рус",
    fullName: "русский",
  },
];

export const LangSelect = ({ className }) => {
  const { language } = useSelector((state) => state.app);
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  //
  const { setIsComponentVisible, ref, isComponentVisible } = UseVisible();

  const changeLanguageHandler = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <div className={cn(styles.langWrapper, className)}>
      <img src={LangIcon} alt="lang" />
      <div className={styles.langSelect} ref={ref}>
        <button
          className={styles.current}
          onClick={() => setIsComponentVisible(!isComponentVisible)}
        >
          {language.value}
          <img src={DownDropIcon} alt="dropdown" />
        </button>
        {isComponentVisible && (
          <motion.nav
            className={styles.langList}
            ref={ref}
            initial={{ scale: 0, translate: "-50%" }}
            animate={{ scale: 1, translate: "-50%" }}
          >
            {languages.map((item) => (
              <li
                key={item.id}
                onClick={() => {
                  dispatch(appActions.setLanguage(item));
                  changeLanguageHandler(item.id);
                  setIsComponentVisible(false);
                }}
                className={styles.selBtn}
              >
                {item.fullName}
              </li>
            ))}
          </motion.nav>
        )}
      </div>
    </div>
  );
};
