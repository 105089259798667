import React from "react";
//

import { DelegatesHeader } from "features";
import { useTranslation } from "react-i18next";
import { Statistic } from "features/Statistic/Statistic";

export const StatisticPage = () => {
  const { t } = useTranslation();

  return (
    <div>
      <DelegatesHeader nonFilter text={t("home.header.stats")} />
      <Statistic />
    </div>
  );
};
