import React from "react";
//
import styles from "./Menu.module.scss";
import { useSelector } from "react-redux";
import classNames from "classnames";
//
import { motion } from "framer-motion";
import { UseSelect } from "./UseSelect";
import { useTranslation } from "react-i18next";

export const colors = [
  "#F69B1E",
  "#A456E2",
  "#17E198",
  "#E830B4",
  "brown",
  "#E2DA0C",
  "#2DC245",
  "#3572CD",
  "red",
  "#25D7EF",
  "pink",
  "blue",
];

export const Menu = () => {
  const { selectedRegion, regions } = useSelector((state) => state.stats);
  const { selectPage } = UseSelect();
  const { t } = useTranslation();

  const replaceName = (item) => {
    if (item.regionId === 4948) {
      return t("stats.kyrgyzstan");
    }
    if (item.regionId === 8000) {
      return t("etnos");
    }
    if (item.regionId === 8001) {
      return t("migrant");
    }

    return item.regionName;
  };

  if (!regions.length) return;

  return (
    <motion.div
      className={styles.menu}
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
    >
      {regions.map((item, key) => (
        <div className={styles.wrapper} key={key}>
          <div
            className={styles.point}
            style={{ background: colors[key] }}
          ></div>
          <button
            type="button"
            onClick={() => selectPage({ ...item, color: colors[key] })}
            key={item.regionId}
            className={classNames(
              styles.btn,
              item.regionId === selectedRegion?.regionId && styles.activeBtn
            )}
            style={{
              borderBottom:
                item.regionId === selectedRegion?.regionId
                  ? `2px solid ${colors[key]}`
                  : "",
            }}
          >
            {replaceName(item)} -{" "}
            <span className={styles.totalCount}>
              {item.totalCitizens} {t("stats.delegatTitle")}
            </span>
          </button>
        </div>
      ))}
    </motion.div>
  );
};
