import { Link, useNavigate } from "react-router-dom";
//
import UzorIcon from "assets/images/uzor.svg";
//
import styles from "./Delegatitem.module.scss";

export const DelegateClickableItem = ({
  delegateItem,
  className,
  bgClassName,
  num,
}) => {
  const navigate = useNavigate();
  const toDelegate = () => {
    navigate("/delegate/" + delegateItem.id);
  };
  return (
    <div className={className} onClick={toDelegate}>
      <div className={bgClassName}>
        <img src={UzorIcon} alt="uzor" />
      </div>
      <img src={delegateItem.photoPath} alt="avatar" />
      <div>
        <span className={styles.num}>{num}</span>
        <p name="name">
          {delegateItem.lastName} {delegateItem.firstName}{" "}
          {delegateItem.paternalName}
        </p>
        <p name="whereFrom">{delegateItem.location.name}</p>
      </div>
    </div>
  );
};
