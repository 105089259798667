import React, { useEffect } from "react";
//
import { Delegate, Header, Orders, Curultai, Footer, Videos } from "features";
//
import styles from "./HomePage.module.scss";
import { ScrollTop, Splash } from "components";
import { Section } from "./Section/Section";
import { StatSection } from "features/StatSection/StatSection";

export const HomePage = () => {
  const pages = [
    Header,
    Curultai,
    Orders,
    Delegate,
    StatSection,
    Videos,
    Footer,
  ];
  //

  return (
    <>
      {pages.map((Item, key) => (
        <Section key={key} className={styles.section}>
          <Item />
        </Section>
      ))}
      <ScrollTop />
    </>
  );
};
