import React from "react";
//
import s from "./NotFound.module.scss";
//
import NotFoundImg from "assets/images/notfound.png";
import { Link } from "react-router-dom";

export const NotFound = () => {
  return (
    <div className={s.notfound}>
      <img src={NotFoundImg} alt="notfound" />
      <h3>Cтраница не найдена!</h3>
      <h1>404</h1>
      <Link to="/">На главную страницу 👈</Link>
    </div>
  );
};
