import React from "react";
import { motion } from "framer-motion";
//
import styles from "./Loading.module.scss";

export const Loading = () => {
  return (
    <motion.div
      className={styles.ldsRoller}
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      transition={{ ease: "linear", duration: 1 }}
    >
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </motion.div>
  );
};

export const MiniLoading = () => {
  return (
    <div className={styles.ldsellipsis}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
