import React from "react";
//
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

export const DelegateItem = ({ className, delegat }) => {
  const navigate = useNavigate();

  return (
    <motion.div
      className={className}
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      transition={{ duration: 1 }}
      onClick={() => navigate("/delegate/" + delegat.id)}
    >
      <div>
        <img src={delegat.photoPath} alt={delegat.firstName} />
      </div>
      <p name="name">
        {delegat.lastName} {delegat.firstName} {delegat.paternalName}
      </p>
      <p name="whereFrom">{delegat.location.name}</p>
    </motion.div>
  );
};
