import React, { useRef } from "react";
import cn from "classnames";
//
import { motion, useInView } from "framer-motion";

export const Section = ({ className, children }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <motion.section ref={ref} className={cn(className)}>
      {isInView ? children : <div style={{ opacity: 0 }}>{children}</div>}
    </motion.section>
  );
};
