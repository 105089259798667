import React, { useState } from "react";
//
import styles from "./Videos.module.scss";
import { SliderNextButton, SliderPrevButton, Title } from "components";
import { motion } from "framer-motion";

import { SwiperSlide, Swiper } from "swiper/react";
import { Navigation } from "swiper/modules";
import { useTranslation } from "react-i18next";

//
import "swiper/css";
import { useCallback } from "react";
import { useRef } from "react";

//

const links = [
  "https://www.youtube.com/embed/gP7I2QGtPU8",
  "https://www.youtube.com/embed/X7kEPgrRrzc",
  "https://www.youtube.com/embed/gCMcMFEZVcg",
];

export const Videos = () => {
  const { t } = useTranslation();

  const sliderRef = useRef(null);

  return (
    <div className={styles.videos} id="video">
      <Title className={styles.title} text={t("home.title.video")} />
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ ease: "linear", duration: 1 }}
        className={styles.wrapper}
      >
        <SliderPrevButton sliderRef={sliderRef} />
        <Swiper
          slidesPerView={1}
          navigation
          modules={[Navigation]}
          centeredSlides
          loop
          ref={sliderRef}
        >
          <div className={styles.iframe}>
            {links.length &&
              links.map((item) => (
                <SwiperSlide key={item}>
                  <iframe
                    src={item + "?autoplay=1&mute=1"}
                    frameBorder="0"
                    allowFullScreen
                    className={styles.iframe}
                    id="iframe"
                  ></iframe>
                </SwiperSlide>
              ))}
          </div>
        </Swiper>
        <SliderNextButton sliderRef={sliderRef} />
      </motion.div>
    </div>
  );
};
