import React from "react";
//
import s from "./MainCard.module.scss";
//
import { Menu } from "./Menu/Menu";
import { StatsMainMap } from "features/Map/Map";
import { useSelector } from "react-redux";

export const MainCard = () => {
  return (
    <div className={s.mainCard}>
      <Menu />
      <StatsMainMap className={s.map} mapRowClassName={s.mapRow} />
    </div>
  );
};
