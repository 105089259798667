import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  regions: [],
  questionStatuses: [],
  categoryQuestions: [],
  educations: [],
  professions: [],
  academicDegrees: [],
  categoryQuestionsStatistic: null,
  selectedRegion: null,
  selectedQuestionStatus: null,
  selectedCategoryQuestion: null,
  selectedEducation: null,
  selectedProfession: null,
  selectedAcademicDegrees: null,
  selectedChild: null,
};

const statisticSlice = createSlice({
  name: "statistic",
  initialState,
  reducers: {
    setRegions: (state, action) => {
      state.regions = action.payload;
    },
    setSelectedRegion: (state, action) => {
      state.selectedRegion = action.payload;
    },
    setSelectedQuestionStatus: (state, action) => {
      state.selectedQuestionStatus = action.payload;
    },
    setQuestionStatuses: (state, action) => {
      state.questionStatuses = action.payload;
    },
    setSelectedQuestionCategory: (state, action) => {
      state.selectedCategoryQuestion = action.payload;
    },
    setQuestionsCategory: (state, action) => {
      state.categoryQuestions = action.payload;
    },
    setEducations: (state, action) => {
      state.educations = action.payload;
    },
    setProfessions: (state, action) => {
      state.professions = action.payload;
    },
    setAcademicDegree: (state, action) => {
      state.academicDegrees = action.payload;
    },
    setSelectedEducation: (state, action) => {
      state.selectedEducation = action.payload;
    },
    setSelectedProfession: (state, action) => {
      state.selectedProfession = action.payload;
    },
    setSelectedAcademicDegree: (state, action) => {
      state.selectedAcademicDegrees = action.payload;
    },
    setCategoryQuestionStatistic: (state, action) => {
      state.categoryQuestionsStatistic = action.payload;
    },
    setSelectedChild: (state, action) => {
      state.selectedChild = action.payload;
    },
  },
});

export const statisticReducer = statisticSlice.reducer;
export const statisticActions = statisticSlice.actions;
