import React, { useCallback, useRef } from "react";
import { motion } from "framer-motion";
//
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";

//
import styles from "./Slider.module.scss";
import { SlideItem } from "./Item/SlideItem";
import { slider_mocks } from "shared/mocks/slider-mocks";
//
import "swiper/css";
import { SliderNextButton, SliderPrevButton } from "components";

export const Slider = () => {
  const sliderRef = useRef(null);

  return (
    <motion.div
      className={styles.slider}
      initial={{ y: 200 }}
      animate={{ y: 0 }}
      transition={{ type: "spring", stiffness: 100, damping: 20 }}
    >
      <SliderPrevButton sliderRef={sliderRef} />
      <Swiper
        spaceBetween={1}
        modules={[Navigation, Autoplay]}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        loop
        centeredSlides
        slidesPerView={3}
        ref={sliderRef}
        className={styles.swiper}
      >
        {slider_mocks.length &&
          slider_mocks.map((slide, key) => (
            <SwiperSlide key={key}>
              <SlideItem slide={slide} className={styles.slide} />
            </SwiperSlide>
          ))}
      </Swiper>
      <SliderNextButton sliderRef={sliderRef} />
    </motion.div>
  );
};
