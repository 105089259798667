import React, { useEffect, useMemo, useState } from "react";
import cn from "classnames";
import { motion } from "framer-motion";
//
import { LangSelect, Select } from "components";
//
import styles from "./Header.module.scss";
//
import MontainImage from "assets/images/header/montain.png";
import LogoImage from "assets/images/header/header-icon.png";
import TextImage from "assets/images/header/header-text.png";
import { Slider } from "features";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { BurgerMenu } from "./BurgerMenu/BurgerMenu";
import UseVisible from "hooks/UseVisible";
//

export const Header = ({ className }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { ref, setIsComponentVisible, isComponentVisible } = UseVisible();
  const [showBurgerMenu, setShowBurgerMenu] = useState(false);
  const { i18n } = useTranslation();

  const buttons = [
    {
      title: t("home.header.button"),
      onClick: () => navigate("/delegations"),
    },
    {
      title: t("home.header.stats"),
      onClick: () => navigate("/stats"),
    },
    {
      title: t("home.header.monitor"),
      onClick: () => navigate("/monitoring"),
    },
  ];

  const langs = [
    {
      id: "kg",
      value: "кырг",
      fullName: "кыргызча",
      onClick: (lang) => {
        i18n.changeLanguage(lang);
      },
    },
    {
      id: "ru",
      value: "рус",
      fullName: "русский",
      onClick: (lang) => {
        i18n.changeLanguage(lang);
      },
    },
  ];

  const resize = (e) => {
    if (e.target.innerWidth < 1300) {
      return setShowBurgerMenu(true);
    }
    setShowBurgerMenu(false);
  };

  useEffect(() => {
    window.addEventListener("resize", resize);
    resize({ target: { innerWidth: window.innerWidth } });
    return () => {
      window.addEventListener("resize", resize);
    };
  }, []);

  return (
    <>
      <div className={cn(styles.header, className)} id="main">
        <div>
          <motion.div
            className={styles.logoWrapper}
            initial={{ y: -500 }}
            animate={{ y: 0 }}
            transition={{
              type: "spring",
              duration: 1,
            }}
          >
            <Link to="/">
              <img src={LogoImage} alt="logo" className={styles.logo} />
            </Link>
            <div className={styles.nav}>
              <ul>
                {buttons.map((item, key) => (
                  <button
                    key={key}
                    className={styles.delegateBtn}
                    onClick={item.onClick}
                  >
                    {item.title}
                  </button>
                ))}
              </ul>
            </div>
            <div className={styles.btns}>
              <LangSelect className={styles.LangSelect} />
            </div>
            {showBurgerMenu && (
              <div ref={ref}>
                <BurgerMenu
                  setIsOpen={setIsComponentVisible}
                  isOpen={isComponentVisible}
                  navButtons={buttons}
                  langListItems={langs}
                />
              </div>
            )}
          </motion.div>
          <motion.div
            className={styles.textWrapper}
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ type: "spring", stiffness: 100, damping: 10 }}
          >
            <img src={TextImage} alt="logo" className={styles.text} />
          </motion.div>
        </div>
        <motion.img src={MontainImage} alt="bg" className={styles.montain} />
      </div>
      <div className={styles.slider}>
        <Slider />
      </div>
    </>
  );
};

export const DelegatesHeader = ({ nonFilter, text, textStyle }) => {
  const filters = useSelector((state) => state.delegation.filters);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { ref, setIsComponentVisible, isComponentVisible } = UseVisible();
  const [showBurgerMenu, setShowBurgerMenu] = useState(false);

  const filtersData = useMemo(() => {
    if (filters.region && filters.district && filters.ak) {
      return [
        {
          title: t("delegats.filters.region"),
          data: [
            {
              id: 0,
              name: t("delegats.filters.allOblast"),
              deep: 0,
              parentId: 0,
              code: 0,
            },
            ...filters.region,
          ],
          id: "region",
        },
        {
          title: t("delegats.filters.district"),
          data: filters.district,
          id: "district",
        },
        {
          title: t("delegats.filters.ak"),
          id: "ak",
          data: filters.ak,
        },
      ];
    }
    return [];
  }, [filters, i18n.language]);

  const buttons = [
    {
      title: t("home.header.button"),
      onClick: () => navigate("/delegations"),
    },
    {
      title: t("home.header.stats"),
      onClick: () => navigate("/stats"),
    },
    {
      title: t("home.header.monitor"),
      onClick: () => navigate("/monitoring"),
    },
  ];

  const langs = [
    {
      id: "kg",
      value: "кырг",
      fullName: "кыргызча",
      onClick: (lang) => {
        i18n.changeLanguage(lang);
      },
    },
    {
      id: "ru",
      value: "рус",
      fullName: "русский",
      onClick: (lang) => {
        i18n.changeLanguage(lang);
      },
    },
  ];
  const resize = (e) => {
    if (e.target.innerWidth < 1300) {
      return setShowBurgerMenu(true);
    }
    setShowBurgerMenu(false);
  };

  useEffect(() => {
    window.addEventListener("resize", resize);
    resize({ target: { innerWidth: window.innerWidth } });
    return () => {
      window.addEventListener("resize", resize);
    };
  }, []);
  return (
    <motion.div
      initial={{ y: -500 }}
      animate={{ y: 0 }}
      transition={{ type: "spring", duration: 1 }}
    >
      <div className={cn(styles.simpleHeader)}>
        <div>
          <div className={styles.logoWrapper}>
            <Link to="/">
              <img src={LogoImage} alt="logo" className={styles.logo} />
            </Link>
            <div className={styles.nav}>
              <div className={styles.btnWrapper}>
                {buttons.map((item, key) => (
                  <button
                    type="button"
                    key={key}
                    className={styles.delegateBtn}
                    onClick={item.onClick}
                  >
                    {item.title}
                  </button>
                ))}
              </div>
            </div>
            {!showBurgerMenu && <LangSelect className={styles.LangSelect} />}
            {showBurgerMenu && (
              <div ref={ref}>
                <BurgerMenu
                  setIsOpen={setIsComponentVisible}
                  isOpen={isComponentVisible}
                  navButtons={buttons}
                  langListItems={langs}
                />
              </div>
            )}
          </div>
          <p className={cn(styles.mainText, textStyle)}>{text}</p>
        </div>
        <img src={MontainImage} alt="bg" className={styles.montain} />
      </div>
      {!nonFilter && (
        <div className={styles.wrapper}>
          {filtersData.length &&
            filtersData.map((item) => <Select item={item} key={item.id} />)}
        </div>
      )}
    </motion.div>
  );
};
