const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  citizens: [],
  filters: {
    region: [],
    district: [],
    ak: [],
  },
  selected: {
    region: {},
    district: {},
    ak: {},
  },
  citizensResult: [],
  oldCitizenResult: [],
};

const delegationSlice = createSlice({
  name: "delegation",
  initialState,
  reducers: {
    setDelegationData: (state, action) => {
      state.citizens = action.payload;
    },
    setDelegationFilters: (state, action) => {
      state.filters = action.payload;
    },
    setDelegationSelected: (state, action) => {
      state.selected = action.payload;
    },
    setCitizensResult: (state, action) => {
      state.citizensResult = action.payload;
    },
    setOldCitizenResult: (state, action) => {
      state.oldCitizenResult = action.payload;
    },
  },
});

export const delegationActions = delegationSlice.actions;
export const delegationReducer = delegationSlice.reducer;
