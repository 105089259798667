import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
//
import styles from "./ScrollTop.module.scss";
//
import UpIcon from "assets/images/dropdown.svg";

export const ScrollTop = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisible);

    return () => window.removeEventListener("scroll", toggleVisible);
  }, []);

  return (
    visible && (
      <motion.button
        type="button"
        className={styles.btn}
        transition={{ type: "spring", duration: 0.5 }}
        whileHover={{ scale: 1.2 }}
        whileTap={{ scale: 0.9 }}
        text="top"
        onClick={scrollToTop}
      >
        <img src={UpIcon} alt="up" />
      </motion.button>
    )
  );
};
