import React from "react";
//
import styles from "./Orders.module.scss";
import { Title } from "components";
//
import { OrderItem } from "./Item/OrderItem";
import { useTranslation } from "react-i18next";
//
import OrderIcon from "assets/images/orders/order-icon.png";
import OrderIcon2 from "assets/images/orders/order-icon2.png";
//
import OrderKg1 from "assets/pdf/kg/1.pdf";
import OrderKg2 from "assets/pdf/kg/2.pdf";
import OrderKg3 from "assets/pdf/kg/3.pdf";
import OrderKg4 from "assets/pdf/kg/4.pdf";

//
import OrderRu1 from "assets/pdf/ru/1.pdf";
import OrderRu2 from "assets/pdf/ru/2.pdf";
import OrderRu3 from "assets/pdf/ru/3.pdf";
import OrderRu4 from "assets/pdf/kg/4.pdf";

export const Orders = () => {
  const { t, i18n } = useTranslation();

  const orders_mocks = [
    {
      id: 0,
      doc: i18n.language === "kg" ? OrderKg3 : OrderRu3,
      text: t("home.order.doc2"),
      icon: OrderIcon2,
    },
    {
      id: 1,
      doc: i18n.language === "kg" ? OrderKg2 : OrderRu2,
      text: t("home.order.doc1"),
      icon: OrderIcon,
    },

    {
      id: 2,
      doc: i18n.language === "kg" ? OrderKg1 : OrderRu1,
      text: t("home.order.doc3"),
      icon: OrderIcon,
    },
    {
      id: 3,
      doc: i18n.language === "kg" ? OrderKg4 : OrderRu4,
      text: t("home.order.doc4"),
      icon: OrderIcon,
    },
  ];

  return (
    <div className={styles.orders} id="orders">
      <Title text={t("home.title.order")} className={styles.text} />
      <div className={styles.content}>
        {orders_mocks &&
          orders_mocks.map((order) => (
            <OrderItem key={order.id} order={order} className={styles.order} />
          ))}
      </div>
    </div>
  );
};
