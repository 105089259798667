import { configureStore } from "@reduxjs/toolkit";
import { delegationReducer } from "./slices/Delegation.slice";
import { appReducer } from "./slices/App.slice";
import { statisticReducer } from "./slices/Statistic.slice";

export const store = configureStore({
  reducer: {
    delegation: delegationReducer,
    app: appReducer,
    stats: statisticReducer,
  },
});
