import React, { useEffect } from "react";
//
import styles from "./DelegationsPage.module.scss";
import { DelegatesHeader, MapRu, MapKg, DelegateClickable } from "features";
import { UseData } from "hooks/UseData";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export const DelegationsPage = () => {
  const { getDelegationInitalData } = UseData();
  const { language } = useSelector((state) => state.app);
  const { t } = useTranslation();
  useEffect(() => {
    getDelegationInitalData();
  }, []);
  return (
    <>
      <div className={styles.delegations}>
        <DelegatesHeader text={t("delegats.title")} />
        {language.id === "kg" ? <MapKg /> : <MapRu />}
        <DelegateClickable />
      </div>
    </>
  );
};
